@import './shared/styles/_vuetify-overrides.scss';
































































































































#preview_container {
	height: 400px;
	background-color: grey;
}
#cassie_widget__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    bottom: 0;
    background-color: var(--cassie-widget-background);
    color: var(--cassie-widget-text-color);
		height: fit-content;
}

#cassie_widget__container > *:not(:last-child) {
    margin-bottom: 12px;
}

#cassie_widget__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#cassie_widget__close {
    background: none;
    border: none;
    font-size: 24px;
    padding: 0;
    color: var(--widget-close-button-color);
}

#cassie_widget__close:hover {
    cursor: pointer;
}

.cassie-widget--statement > *:not(:last-child) {
    margin-right: 8px;
}
